/* eslint-disable @typescript-eslint/camelcase */
import apiUrl from "../../api_url.global";
import { useInitSciChart } from "@/composition/index";
import {
  apiSaveData,
  apiModifyData,
  apiModifyEvaluation,
  apiDeleteData,
  apiDeleteEvaluationData,
} from "@/composition/api";
import {
  selectedPoints,
  diagnoses,
  token,
  evaluationTagData,
  isChecked,
  selectedModelName,
  anomalyData,
  selectTagIndex,
  dbNum,
  diagnosesid,
  evaluationTagDataShow,
  isEvaluated,
} from "@/composition/store";

export const modalFn = () => {
  const { initSciChart } = useInitSciChart();
  const saveData = (val) => {
    const maxLength = Math.max(...selectedPoints.map((p) => p.length));
    const filterSelectedAry = {
      channel: 0,
      data: [],
    };
    selectedPoints.forEach((item, index) => {
      if (item.length === maxLength) {
        filterSelectedAry.channel = index;
        filterSelectedAry.data.push(item);
      }
    });

    let x1: string;
    let x2: string;
    let y1: string;
    let y2: string;
    let theChannel: string;
    for (let i = 0; i < selectedPoints.length; i++) {
      if (selectedPoints[i].length !== 0) {
        x1 = selectedPoints[i][0].x1Value.toFixed(0);
        x2 = selectedPoints[i][0].x2Value.toFixed(0);
        y1 = selectedPoints[i][0].y1Value.toFixed(0);
        y2 = selectedPoints[i][0].y2Value.toFixed(0);
        theChannel = filterSelectedAry.channel.toString();
      }
    }
    const config: any = {
      url: `${apiUrl.url}
				${dbNum.value}/notes/create`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      method: "post",
      data: {
        id: "",
        diagnosis_id: diagnoses.data[0].diagnosis_id,
        x1: x1,
        x2: x2,
        y1: y1,
        y2: y2,
        channel: theChannel,
        note: `["${val}"]`,
      },
    };
    apiSaveData(config);
  };

  const deleteData = async (val) => {
    const config: any = {
      url: `${apiUrl.url}${dbNum.value}/notes/delete/${val.id}`,
      // apiUrl.url +
      // localStorage.getItem("dbNum") +
      // "/notes/delete/" +
      // val[0].id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      method: "delete",
    };
    await apiDeleteData(config);
    // console.log(val.id);

    // console.log(config);
    console.log(diagnosesid.value);
    await initSciChart(diagnosesid.value);
  };
  const modifyData = async (tagData, val, newChannel) => {
    const config: any = {
      url: `${apiUrl.url}${dbNum.value}/notes/modify`,
      headers: {
        // Authorization:
        // 	'Bearer ' +
        // 	localStorage.getItem(
        // 		'access_token'
        // 	),
        "Content-Type": "application/json",
      },
      method: "put",
      data: {
        id: tagData.id,
        diagnosis_id: diagnoses.data[0].diagnosis_id,
        x1: tagData.x1,
        x2: tagData.x2,
        channel: `${newChannel}`,
        note: `["${val}"]`,
      },
    };
    // console.log(selectTagIndex);
    // console.log(
    // 	tagData,
    // 	val,
    // 	newChannel
    // );
    console.log(config);
    await apiModifyData(config);
    // await initSciChart(
    // 	diagnosesid.value
    // );
  };
  const deleteEvaluationData = async (val) => {
    console.log(val.id);

    const config: any = {
      // 暫時停用，移除網址
      url: apiUrl.url + localStorage.getItem("dbNum") + "/evaluation/" + val.id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      method: "delete",
    };
    console.log(config);
    apiDeleteEvaluationData(config);

    // axios(config)
    // 	.then(() => {
    // 		evaluationTagData.data.length = 0;
    // 		evaluationTagData.data.push({
    // 			id: "",
    // 			channel: "",
    // 			evaluator: "",
    // 			evaluation: "",
    // 			model_name: "",
    // 			x1: "",
    // 			x2: ""
    // 		});
    // 		initSciChart();
    // 	})
    // 	.catch((err) => {
    // 		console.log(err);
    // 	});
  };
  const modifyEvaluationData = async (data, val) => {
    console.log(data);
    console.log(val);

    const maxLength = Math.max(...selectedPoints.map((p) => p.length));
    const filterSelectedAry = {
      channel: 0,
      data: [],
    };
    selectedPoints.forEach((item, index) => {
      if (item.length === maxLength) {
        filterSelectedAry.channel = index;
        filterSelectedAry.data.push(item);
      }
    });
    let x1: string;
    let x2: string;
    let theChannel: string;
    for (let i = 0; i < selectedPoints.length; i++) {
      if (selectedPoints[i].length !== 0) {
        x1 = selectedPoints[i][0].x1Value.toFixed(0);
        x2 = selectedPoints[i][0].x2Value.toFixed(0);
        theChannel = filterSelectedAry.channel.toString();
      }
    }
    const aiTagNote1 = anomalyData.data[0].result
      .flat(Infinity)
      .filter((e) => e > 0);
    const aiTagNote2 = aiTagNote1.filter((item, index) => {
      return aiTagNote1.indexOf(item) === index;
    });
    console.log(anomalyData.data[0].result);
    console.log(aiTagNote1);
    console.log(aiTagNote2);

    const evaluation: any = {
      diagnosis_id: diagnoses.data[0].diagnosis_id,
      model_name: selectedModelName.value,
      evaluator: JSON.parse(localStorage.getItem("userinfo")).username,
      score: "",
      ai_sequence: [JSON.stringify(anomalyData.data[0].result)],
      evaluator_sequence: [],
    };
    console.log(evaluation);
    console.log(isChecked.value);
    console.log(aiTagNote2.toString());
    console.log(selectedModelName.value);
    console.log(val);
    // console.log(
    // 	evaluationTagDataShow.value[0]
    // 		.evaluation
    // );
    if (isEvaluated.value !== true) {
      if (selectedModelName.value === "model_b1") {
        if (aiTagNote2.toString() === "1") {
          evaluation.evaluation = `${val}:abnormal`;
        }
      } else if (selectedModelName.value === "model_b1_009") {
        if (aiTagNote2.toString() === "1") {
          evaluation.evaluation = `${val}:abnormal`;
        }
      } else if (selectedModelName.value === "model_b2") {
        if (aiTagNote2.toString() === "1") {
          evaluation.evaluation = `${val}:abnormal`;
        }
      } else if (selectedModelName.value === "model_b2_05") {
        if (aiTagNote2.toString() === "1") {
          evaluation.evaluation = `${val}:abnormal`;
        }
      } else if (selectedModelName.value === "model_b_vpc_mi") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:VPC`;
        } else if (evaluationTagDataShow.value[0].evaluation === "2") {
          evaluation.evaluation = `${val}:mi (std/ste)`;
        }
      } else if (selectedModelName.value === "v1.0.1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:vpc`;
        } else if (evaluationTagDataShow.value[0].evaluation === "2") {
          evaluation.evaluation = `${val}:mi`;
        } else if (evaluationTagDataShow.value[0].evaluation === "3") {
          evaluation.evaluation = `${val}:arrhythmia`;
        } else if (evaluationTagDataShow.value[0].evaluation === "4") {
          evaluation.evaluation = `${val}:vpc + arrhythmia`;
        } else if (evaluationTagDataShow.value[0].evaluation === "5") {
          evaluation.evaluation = `${val}:mi + arrhythmia`;
        }
      } else if (selectedModelName.value === "model_b3") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:arrhythmia`;
        }
      } else if (selectedModelName.value === "v1.0.2") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:vpc`;
        } else if (evaluationTagDataShow.value[0].evaluation === "2") {
          evaluation.evaluation = `${val}:mi`;
        } else if (evaluationTagDataShow.value[0].evaluation === "3") {
          evaluation.evaluation = `${val}:arrhythmia`;
        } else if (evaluationTagDataShow.value[0].evaluation === "4") {
          evaluation.evaluation = `${val}:vpc + arrhythmia`;
        } else if (evaluationTagDataShow.value[0].evaluation === "5") {
          evaluation.evaluation = `${val}:mi + arrhythmia`;
        }
      } else if (selectedModelName.value === "model_a1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:vpc/mi`;
        }
      } else if (selectedModelName.value === "model_a1_v1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:VPC,ST`;
        }
      } else if (selectedModelName.value === "v1.0.3") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:vpc`;
        } else if (evaluationTagDataShow.value[0].evaluation === "2") {
          evaluation.evaluation = `${val}:ste`;
        } else if (evaluationTagDataShow.value[0].evaluation === "3") {
          evaluation.evaluation = `${val}:std`;
        } else if (evaluationTagDataShow.value[0].evaluation === "4") {
          evaluation.evaluation = `${val}:arrhythmia`;
        }
      } else if (selectedModelName.value === "model_std_a1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:std`;
        }
      } else if (selectedModelName.value === "model_ste_a1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:ste`;
        }
      } else if (selectedModelName.value === "model_vpc_a1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:VPC`;
        }
      } else if (selectedModelName.value === "model_ensemble_a1") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:vpc`;
        } else if (evaluationTagDataShow.value[0].evaluation === "2") {
          evaluation.evaluation = `${val}:ste`;
        } else if (evaluationTagDataShow.value[0].evaluation === "3") {
          evaluation.evaluation = `${val}:std`;
        }
      } else if (selectedModelName.value === "model_blw") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:BLW`;
        }
      } else if (selectedModelName.value === "v1.0.4") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:vpc`;
        } else if (evaluationTagDataShow.value[0].evaluation === "2") {
          evaluation.evaluation = `${val}:ste`;
        } else if (evaluationTagDataShow.value[0].evaluation === "3") {
          evaluation.evaluation = `${val}:std`;
        } else if (evaluationTagDataShow.value[0].evaluation === "4") {
          evaluation.evaluation = `${val}:arrhythmia`;
        }
      } else if (selectedModelName.value === "model_b3_v2") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:arrhythmia`;
        }
      } else if (selectedModelName.value === "model_unknown") {
        if (evaluationTagDataShow.value[0].evaluation === "1") {
          evaluation.evaluation = `${val}:Unknown`;
        }
      }
      (evaluation.channel = data.channel),
        (evaluation.x1 = data.x1.toString()),
        (evaluation.x2 = data.x2.toString());
    } else {
      (evaluation.evaluation = `${val}:Normal Sinus Rhythm`),
        (evaluation.channel = theChannel),
        (evaluation.x1 = x1),
        (evaluation.x2 = x2);
    }
    if (isChecked.value !== true) {
      aiTagNote2.forEach((item) => {
        if (item === 1 && selectedModelName.value === "model_b_vpc_mi") {
          evaluation.evaluation = `${val}:VPC`;
        } else if (item === 2 && selectedModelName.value === "model_b_vpc_mi") {
          evaluation.evaluation = `${val}:mi (std/ste)`;
        } else if (item === 1 && selectedModelName.value !== "model_b_vpc_mi") {
          evaluation.evaluation = `${val}:abnormal`;
        }
      });
    } else {
      (evaluation.evaluation = `${val}:Normal Sinus Rhythm`),
        (evaluation.channel = theChannel),
        (evaluation.x1 = x1),
        (evaluation.x2 = x2);
    }

    const config: any = {
      url: `${apiUrl.url}${dbNum.value}/evaluation`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
      method: "post",
      data: evaluation,
    };
    console.log(config);
    await apiModifyEvaluation(config);
    // await initSciChart(
    // 	diagnosesid.value
    // );
  };
  return {
    saveData,
    deleteData,
    modifyData,
    deleteEvaluationData,
    evaluationTagData,
    isChecked,
    modifyEvaluationData,
    selectedModelName,
    anomalyData,
    evaluationTagDataShow,
  };
};
